$.fn.tilesWidget = function( options ) {

	$.fn.tilesWidget.peopleAdmin(this);
	$.fn.tilesWidget.tileClicks(this);

};

$.fn.tilesWidget.tileAdmin = function(array) {
	array.each(function() {
		// For each tile, get the title of each and make it a variable with whitespace stripped out
		var title = $('h2.name', this).text().replace(/[^A-Z0-9]/ig, "");
		// Give each description the ID of their tile's title
		var desc = $('.description', this);
		desc.attr('id', title);
		// Put descriptions into descriptions container
		$(this).parent().siblings('.descriptions-container').append(desc);
		// Wrap each tile in an anchor and make it's href attribute that of it's title
		href_title = "#" + title;
		$(this).wrap('<a class="tile-wrapper"></a>');
		$(this).parent().attr('href', href_title);
	});
};

$.fn.tilesWidget.getViewport = function(viewportWidth) {
	if (viewportWidth <= 580) {
		return "micro";
	} else if (viewportWidth >= 581 && viewportWidth <= 766) {
		return"mini";
	} else if (viewportWidth >= 767 && viewportWidth <= 991) {
		return "small";
	} else if (viewportWidth >= 992 && viewportWidth <= 1199) {
		return "medium";
	} else if (viewportWidth >= 1200) {
		return "large";
	} else {
		return "";
	}
};

$.fn.tilesWidget.peopleAdmin =function (that) {
	var windowWidth = $(window).width();
	var viewport = $.fn.tilesWidget.getViewport(windowWidth);

	var columns;
	if (viewport == "large") {
		columns = 4;
	} else if (viewport == "medium" || viewport == "small") {
		columns = 3;
	} else {
		columns = 2;
	}
	columns = 4;

	// Check if the dynamic container already exists (in the context of a resize). If it does, first delete it before building again.
	if ($('.dynamic-container').length) {
		$('.dynamic-container').remove();
		that.find('.col-tiles.people').removeClass('hide');
	}

	// Get html, create new container populate with html and hide original content
	var content = that.find('.col-tiles.people').html();
	that.find('.person-tiles-intro').after('<div class="dynamic-container"><div class="col col-tiles people"></div></div>');
	$('.dynamic-container .col-tiles.people').html(content);
	that.find(' > .col-tiles.people').addClass('hide');

	// re-get the tiles after filtering
	var tiles = $(".dynamic-container .col-tiles.people > .tile.person");


	for(var i = 0; i < tiles.length; i+=columns) {
		tiles.slice(i, i+columns).wrapAll("<div class='tiles'></div>");
	}

	$('.dynamic-container .col-tiles.people .tiles').wrap('<div class="sub-row"></div>');


	$('.dynamic-container .col-tiles.people .sub-row').each(function() {
		// Make a container for people descriptions
		$(this).append('<div class="descriptions-container"></div>');

		$.fn.tilesWidget.tileAdmin($('.tiles .person.tile', this));
	});
};

$.fn.tilesWidget.tileClicks = function() {
	// Add an event listener to the anchors and change classes for styling appropriately!
	$('.dynamic-container .col-tiles.people .tile-wrapper').on("click", function(e) {
		e.preventDefault();

		//console.log("clicked");
		if( $(this).hasClass('active') ) return;

		// Remove active classes
		$('.dynamic-container .col-tiles.people .tile-wrapper').removeClass('active');
		$('.dynamic-container .col-tiles.people .descriptions-container').css("height", "0px");
		$('.dynamic-container .col-tiles.people .description').removeClass('active');

		// Add active classes and do animations
		$(this).addClass('active');
		var active_id = $(this).attr('href');

		var containerHeight = $(active_id).height();
		var arrowHeight = 0;
		var cssHeight = containerHeight + arrowHeight + "px";
		$(active_id).parent().animate({
			height: cssHeight
		}, 500, function() {
			$(active_id).addClass('active');
		});
	});
};
